import React from "react";
import styles from "./ProjectsComponent.module.css";

const ProjectsComponent = () => {
  const projects = [
    {
      title: "Salesforce Portal",
      description: "Developed a custom Salesforce Employee Portal that enables non-Salesforce users to view, edit, and request updates to their contact and related records. Additionally, it allows posting open job positions and applying with just one click.",
      link: "#",
      tech: ["Salesforce", "Apex", "LWC", "Digital Experience", "CMS"],
    },
    {
      title: "Integrated Lead Reporting System",
      description: "Integrated reports from multiple systems into a single database and created a live, transitioning chart to display daily and weekly lead data, providing real-time insights for decision-making.",
      link: "#",
      tech: ["Google App Script", "Google Sheets", "HTML", "CSS", "Javascript"],
    },    
    {
      title: "Contractor Management Tool",
      description: "Built a powerful web app that helps contractors manage both their finances and client relationships. From tracking income and expenses to generating professional invoices and estimates, this tool keeps everything organized in one place, making it easier to stay on top of your business.",
      link: "#",
      tech: ["PostgreSQL", "FastAPI", "Next.js", "React"], 
    }    
  ];

  return (
    <section className={styles.projects}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>Recent Projects</h2>
        <p className={styles.sectionSubtitle}>
          Here are a few examples of the impactful work I’ve delivered for clients. Each project reflects my dedication to delivering solutions that drive success.
        </p>
        <div className={styles.projectsGrid}>
          {projects.map((project, index) => (
            <div className={styles.projectCard} key={index}>
              <h3 className={styles.projectTitle}>{project.title}</h3>
              <p className={styles.projectDescription}>{project.description}</p>
              <ul className={styles.projectTech}>
                {project.tech.map((tech, index) => (
                  <li key={index} className={styles.techItem}>{tech}</li>
                ))}
              </ul>
              {/* <a href={project.link} className={styles.projectLink}>View Project</a> */}
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.viewAllButton}
            onClick={() => window.location.href = "/projects"}
          >
            View All Projects
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProjectsComponent;
