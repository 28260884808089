import React from "react";
import styles from "./Footer.module.css";
import { FaLinkedin, FaGithub, FaTwitter } from "react-icons/fa"; // Social links

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerTop}>
          <p className={styles.footerText}>© 2024 Daniel Rangel. All rights reserved.</p>
          <div className={styles.socialLinks}>
            <a href="https://www.linkedin.com/in/daniel-r-rangel-dc88/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className={styles.socialIcon} />
            </a>
            {/* <a href="https://github.com/danielrangel" target="_blank" rel="noopener noreferrer">
              <FaGithub className={styles.socialIcon} />
            </a>
            <a href="https://twitter.com/danielrangel" target="_blank" rel="noopener noreferrer">
              <FaTwitter className={styles.socialIcon} />
            </a> */}
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p className={styles.footerSmallText}>
            Designed and developed by <a href="mailto:exhalesoftwaresolutions@gmail.com">Daniel Rangel</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
