import React from "react";
import styles from "./ProjectsPage.module.css";
import { 
  FaCubes, 
  FaToolbox, 
  FaChartLine, 
  FaEnvelopeOpenText, 
  FaServer, 
  FaChartPie, 
  FaShoppingCart, 
  FaUsers, 
  FaPaintBrush, 
  FaCut, 
  FaSalesforce, 
  FaDatabase, 
  FaTools, 
  FaChartBar, 
  FaHeartbeat,
  FaCheckCircle,
  FaPalette
} from "react-icons/fa";
import { SiNextdotjs, SiPostgresql, SiJavascript, SiReact } from "react-icons/si";

const ProjectsPage = () => {
  const projects = [
    {
      title: "Salesforce Portal",
      description: "Streamline workforce data and recruitment with a fully custom Salesforce portal web app designed for scalability and ease of use.",
      features: [
        "Granular employee access controls",
        "Seamless job posting and one-click applications",
        "Interactive contractor invoice generation",
      ],
      tech: ["Salesforce", "Apex", "LWC", "CMS", "Digital Experience"],
      icon: <FaSalesforce />,
    },
    {
      title: "Data Visualization Dashboard",
      description: "A sleek dashboard for visualizing complex data using advanced charts and intuitive user flows that enables management to make informed business decisions while cutting out the need for manual labor and third party spreadsheet needs.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      tech: ["Salesforce", "Admin"],
      icon: <FaDatabase />,
    },
    {
      title: "Healthcare Management",
      description: "Designed for healthcare professionals, this secure web app simplifies patient and encounter tracking. With robust login-protected access and user-friendly workflows, it ensures seamless management of sensitive data while saving time and reducing errors.",
      features: [
        "Real-time patient monitoring and tracking",
        "Automated appointment scheduling",
        "Scalable architecture for growing practices"
      ],
      icon: <FaHeartbeat />,
      tech: ["React", "Node.js", "PostgreSQL", "AWS"]
    },
    {
      title: "E-Commerce Platform",
      description: "A sleek and intuitive e-commerce solution for fitness apparel. Customers can shop by category, size, or gender, with an ultra-smooth checkout process powered by Stripe integration. Boost sales with a platform that combines ease of use and security.",
      features: [
        "Seamless multi-step checkout flow",
        "Real-time inventory updates",
        "Responsive design optimized for mobile and desktop",
      ],
      icon: <FaShoppingCart />,
      tech: ["React", "Java", "Bootstrap", "Postgres", "Git"]
    },
    {
      title: "Dynamic Modular Content Platform",
      description: "Built with modular components for efficiency and brand consistency, this platform features a robust CMS with customizable blogs and a tailored Strapi instance. Perfect for scaling businesses looking to simplify content creation and management while staying ahead of the competition.",
      features: [
        "Custom - Reusable, React components",
        "Brand Consistency",
        "Responsive UI - Designed for all devices",
      ],
      icon: <FaCubes />,
      tech: ["React", "Strapi", "GraphQL", "Docker", "NextJS", "CSS"]
    },
    {
      title: "Salesforce Revenue",
      description: "A custom Salesforce solution to accurately track and calculate revenue. Designed for flexibility, it works with any object and provides insights for both past and future years to drive data-driven decision-making.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Quarterly and monthly insights",
      ],
      icon: <FaChartLine />,
      tech: ["Salesforce", "Apex", "LWC", "Flow"]
    },
    {
      title: "Client Feedback",
      description: "Request client feedback efficiently with single-record or bulk list view options. Includes customizable email templates and a digital experience site for non-Salesforce users to submit data and create related records seamlessly.",
      features: [
        "Bulk email sending with customizable templates",
        "Integration with Salesforce digital experience sites",
        "Automated record creation from feedback submissions",
        "Real-time analytics for feedback trends",
      ],
      icon: <FaEnvelopeOpenText />,
      tech: ["Salesforce", "Apex", "Digital Experience", "JavaScript", "LWC", "Flows"]
    },
    {
      title: "Salesforce Migration",
      description: "Revolutionized a Salesforce account by migrating all data, automations, and sites to a clean new instance. Reduced costs, eliminated waste data, and streamlined processes for future growth.",
      features: [
        "End-to-end data migration with integrity checks",
        "Optimization of automations and workflows",
        "Data deduplication and cleansing",
        "Custom user training materials and documentation",
        "Post-migration performance monitoring"
      ],
      icon: <FaServer />,
      tech: ["Salesforce", "Apex", "Data Loader", "Flow"]
    },
    {
      title: "Lead Reporting",
      description: "Integrated Gmail, Google App Scripts, and Google Sheets with third-party CRMs to develop an automated lead reporting system. Delivered daily and weekly reports with actionable insights for better decision-making and performance tracking.",
      features: [
        "Custom Google Sheets dashboards with live updates",
        "Scheduled daily and weekly report generation",
        "Integration with third-party CRM systems",
      ],
      icon: <FaChartPie />,
      tech: ["Google App Scripts", "Google Sheets", "HTML", "CSS", "JavaScript"]
    },
    {
      title: "Staffing Solutions",
      description: "Created a tailored process for staffing agencies to search and filter candidates based on job requirements. Enhanced efficiency by enabling users to create and manage candidate lists directly within Salesforce.",
      features: [
        "Advanced candidate filtering based on custom criteria",
        "Bulk candidate list creation and management",
        "Seamless integration with job boards and third-party systems",
        "Real-time notifications for candidate status updates",
        "Intuitive dashboards for placement analytics"
      ],
      icon: <FaUsers />,
      tech: ["Salesforce", "Apex", "Lightning Web Components", "Flow"]
    },
    {
      title: "Contractor Management",
      description: "Developed a comprehensive web app for contractors to manage finances and client relationships. Features include contact storage, professional invoice generation, project status tracking, and detailed estimates for seamless project execution.",
      features: [
        "Professional invoice and estimate generation",
        "Integrated financial reporting and analytics",
        "Secure client contact and relationship management",
        "Mobile-friendly design for on-the-go access"
      ],
      icon: <FaToolbox />,
      tech: ["PostgreSQL", "FastAPI", "React", "Node.js"],
      logo: '/saglogo.png',
      url: 'https://www.saguaropallets.com/'
    },
    {
      title: "Nail Salon Portfolio",
      description: "Designed a visually stunning website for a nail salon, showcasing previous work and providing an easy booking system. Delivered an affordable solution to boost visibility and attract more clients.",
      features: [
        "Gallery showcasing nail art and salon work",
        "Responsive design for mobile and desktop",
        "Custom branding to reflect salon aesthetics",
        "SEO optimization for local search visibility"
      ],
      icon: <FaPaintBrush />,
      tech: ["Next.js", "React", "CSS", "TailwindCSS"],
      logo: '/apple-touch-icon.png',
      url: 'https://www.honeyvnails.com/'
    },
    {
      title: "Online Barber",
      description: "Crafted a sleek website for a barbershop, featuring a home page and a gallery of haircut styles. Customers can easily browse contact details and point out their preferred styles, while in-store QR codes provide instant access to the gallery for a seamless experience.",
      features: [
        "Gallery of haircut styles with detailed descriptions",
        "QR code integration for instant in-store access",
        "Contact details, location and business hours displayed prominently",
        "Mobile-first design for on-the-go accessibility",
      ],
      icon: <FaCut />,
      tech: ["Next.js", "React", "QR Code Integration", "CSS"],
      logo: "/barberlogo.jpg",
      url: 'https://www.guerosbarbershop.com/'
    },
    {
      title: "Painting Company Website",
      description: "Developed a professional website for a painting company, showcasing their services, portfolio, and contact options. The site is designed to highlight their expertise and attract new clients through a clean and modern interface.",
      features: [
        "Interactive portfolio with high-quality images",
        "Service pages detailing offerings and expertise",
        "Contact form for inquiries and quotes",
        "Custom branding to reflect the company's professionalism",
      ],
      icon: <FaPalette />,
      tech: ["Next.js", "React", "CSS", "SEO Optimization"],
      logo: "/paintlogo.jpg",
      url: "https://www.paintjg.com/"
    }
    
    // Add more projects as needed
  ];

  return (
    <section className={styles.projectsPage}>
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>Innovative Projects</h1>
        <p className={styles.pageSubtitle}>
          Explore a selection of projects that showcase innovation, technical expertise, and real-world impact. Every project is designed with precision to meet diverse business needs.
        </p>
        <div className={styles.projectsGrid}>
          {projects.map((project, index) => (
            <div className={styles.projectCard} key={index}>
              <div className={styles.projectIcon}>{project.icon}</div>
              <h2 className={styles.projectTitle}>{project.title}</h2>
              {project.logo && (
                <a href={project.url ? project.url: '#'} target="_blank">
                  <img
                    src={project.logo}
                    alt={`${project.title} Logo`}
                    className={styles.projectLogo}
                  />
                </a>
              )}
              <p className={styles.projectDescription}>{project.description}</p>
              <ul className={styles.featuresList}>
                {project.features.map((feature, i) => (
                  <li key={i} className={styles.featureItem}>
                    <span className={styles.featureItemIcon}>
                      <FaCheckCircle />
                    </span>
                    {feature}
                  </li>
                ))}
              </ul>
              <div className={styles.techStack}>
                {project.tech.map((tech, i) => (
                  <span key={i} className={styles.techBadge}>
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className={styles.ctaContainer}>
          <h2 className={styles.ctaTitle}>Ready to bring your idea to life?</h2>
          <p className={styles.ctaSubtitle}>
            Let's collaborate and turn your vision into reality. Whether you're looking for custom web development or an innovative solution, I’m here to help.
          </p>
          <a href="/contact" className={styles.ctaButton}>
            Start Your Project Today!
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProjectsPage;
