import React from "react";
import styles from "./ContactComponent.module.css";
import { FaPhoneAlt, FaEnvelope, FaLinkedin, FaGithub } from "react-icons/fa"; // Add icons for social links

const Contact = () => {
  return (
    <section className={styles.contact}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>Let's Connect!</h2>
        <p className={styles.sectionSubtitle}>
          Whether you're looking for a developer to collaborate with or need help on a project, I'm always open to new opportunities. Reach out to me below! 
        </p>
        <div className={styles.contactMethods}>
          <div className={styles.contactMethod}>
            <FaEnvelope className={styles.contactIcon} />
            <a href="mailto:exhalesoftwaresolutions@gmail.com" className={styles.contactLink}>exhalesoftwaresolutions@gmail.com</a>
          </div>
          <div className={styles.contactMethod}>
            <FaPhoneAlt className={styles.contactIcon} />
            <a href="tel:+14802820569" className={styles.contactLink}>+1 (480) 282-0569</a>
          </div>
          {/* <div className={styles.contactMethod}>
            <FaLinkedin className={styles.contactIcon} />
            <a href="https://www.linkedin.com/in/danielrangel" className={styles.contactLink} target="_blank" rel="noopener noreferrer">
              LinkedIn Profile
            </a>
          </div> */}
          {/* <div className={styles.contactMethod}>
            <FaGithub className={styles.contactIcon} />
            <a href="https://github.com/danielrangel" className={styles.contactLink} target="_blank" rel="noopener noreferrer">
              GitHub Profile
            </a>
          </div> */}
        </div>
        <div className={styles.cta}>
          <p>Ready to work together? <a href="/contact" className={styles.ctaButton} target="_blank">Let’s Talk</a></p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
